/**
 * Internal dependencies
 */
import { getCookie, removeCookie, setCookie } from '../utils/cookies';

export function isIE() {
	const ua = window.navigator.userAgent || '';
	if ( -1 !== ua.indexOf( 'MSIE ' ) || -1 !== ua.indexOf( 'Trident/' ) ) {
		return true;
	} //end if

	return false;
} //end isIE()

export function doesVisitorParticipate( settings ) {
	if ( ! settings ) {
		return false;
	} //end if

	if ( isIE() ) {
		return false;
	} //end if

	if ( ! areCookiesEnabled() ) {
		return false;
	} //end if

	const { gdprCookie } = settings;
	if ( ! isGdprAccepted( gdprCookie ) ) {
		return false;
	} //end if

	if ( isVisitorExcludedFromTesting() ) {
		return false;
	} //end if

	const { participationChance } = settings;
	return doesVisitorParticipateAccordingToTheCookie( participationChance );
} //end doesVisitorParticipate()

export function isVisitorExcludedFromTesting() {
	return !! getCookie( 'nabIsVisitorExcluded' );
} //end isVisitorExcludedFromTesting()

function areCookiesEnabled() {
	setCookie( 'nabCheckWritePermission', true );
	if ( ! getCookie( 'nabCheckWritePermission' ) ) {
		return false;
	} //end if

	removeCookie( 'nabCheckWritePermission' );
	return true;
} //end areCookiesEnable();

function isGdprAccepted( gdprCookie ) {
	if ( ! gdprCookie ) {
		return true;
	} //end if

	return !! getCookie( gdprCookie );
} //end isGdprAccepted()

function doesVisitorParticipateAccordingToTheCookie( participationChance ) {
	if (
		! isVisitorParticipationCookieValid() ||
		shouldVisitorParticipationCookieBeUpdated( participationChance )
	) {
		generateVisitorParticipationCookie( participationChance );
	} //end if

	const participation = getCookie( 'nabParticipation' );
	return 0 === participation.indexOf( 'true' );
} //end doesVisitorParticipateAccordingToTheCookie()

function isVisitorParticipationCookieValid() {
	const participation = getCookie( 'nabParticipation' ) || '';
	return /^(true|false),[0-9]{1,3}$/.test( participation );
} //end isVisitorParticipationCookieValid()

function shouldVisitorParticipationCookieBeUpdated( participationChance ) {
	const participation = getCookie( 'nabParticipation' ) || '';
	return participation.split( ',' )[ 1 ] !== `${ participationChance }`;
} //end shouldVisitorParticipationCookieBeUpdated()

function generateVisitorParticipationCookie( participationChance ) {
	const random = Math.min( 100, Math.floor( Math.random() * 101 ) );
	const shouldVisitorParticipate =
		random <= participationChance ? 'true' : 'false';
	setCookie(
		'nabParticipation',
		`${ shouldVisitorParticipate },${ participationChance }`,
		{ expires: 120 }
	);
} //end generateVisitorParticipationCookie()
