export const type = 'nab/referring-url';

export function validate( attributes ) {
	const { condition, value } = attributes;

	const referrer = document.referrer;

	switch ( condition ) {
		case 'is-equal-to':
			return referrer === value;

		case 'is-not-equal-to':
			return referrer !== value;

		case 'contains':
			return referrer && referrer.includes( value );

		case 'does-not-contain':
			return ! referrer || ( referrer && ! referrer.includes( value ) );
	} //end switch

	return false;
}
