/**
 * Internal dependencies
 */
import { getCookie } from '../utils/cookies';

export function getAllActions( experiments ) {
	const alternativeCookie = getCookie( 'nabAlternative' );

	return Object.keys( experiments )
		.map( ( id ) => Number.parseInt( id ) )
		.reduce( ( result, experimentId ) => {
			const experiment = experiments[ experimentId ];
			experiment.goals.forEach( ( goal ) => {
				const relevantActions = goal.conversionActions.map(
					( action ) => ( {
						...action,
						experiment: experimentId,
						alternative:
							alternativeCookie % experiment.alternatives.length,
						goal: goal.id,
					} )
				);
				result = [ ...result, ...relevantActions ];
			} );

			return result;
		}, [] );
} //end getAllActions()

export function getConversionEvents( experiments, actionMatcher ) {
	return Object.keys( experiments )
		.map( ( id ) => Number.parseInt( id ) )
		.reduce( ( result, experimentId ) => {
			const relevantGoals = experiments[
				experimentId
			].goals.filter( ( goal ) =>
				hasMatchingAction( goal, actionMatcher )
			);
			const eventsFromGoals = relevantGoals.map( ( goal ) => ( {
				goal: goal.id,
				experiment: experimentId,
			} ) );

			return [ ...result, ...eventsFromGoals ];
		}, [] );
} //end getConversionEvents()

function hasMatchingAction( goal, actionMatcher ) {
	return goal.conversionActions.reduce(
		( memo, action ) => memo || actionMatcher( action ),
		false
	);
} //end hasMatchingAction()
