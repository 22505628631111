/**
 * Internal dependencies
 */
import { hooks } from '../conversions';

import listenToClickEvent from './click';
import listenToClickExternalLinkEvent from './click-external-link';
import prepareFormForSyncingEventInWordPress from './form-submission';
import listenToVideoPlaybackEvent from './video-playback';

export function initDefaultListeners( convert ) {
	hooks.addAction(
		'nab.addEventListenersRequiredByAction',
		'nab/listenToClickEvent',
		( action ) =>
			'nab/click' === action.type && listenToClickEvent( action, convert )
	);

	hooks.addAction(
		'nab.addEventListenersRequiredByAction',
		'nab/listenToClickExternalLinkEvent',
		( action ) =>
			'nab/click-external-link' === action.type &&
			listenToClickExternalLinkEvent( action, convert )
	);

	const formActions = [ 'nab/form-submission', 'nab/wc-order' ];
	hooks.addAction(
		'nab.addEventListenersRequiredByAction',
		'nab/prepareFormForSyncingEventInWordPress',
		( action ) =>
			formActions.includes( action.type ) &&
			prepareFormForSyncingEventInWordPress( action )
	);

	hooks.addAction(
		'nab.addEventListenersRequiredByAction',
		'nab/listenToVideoPlaybackEvent',
		( action ) =>
			'nab/video-playback' === action.type &&
			listenToVideoPlaybackEvent( action, convert )
	);
} //end initDefaultListeners()
