export default function listenToClickEvent( action, convert ) {
	const {
		attributes: { mode },
		experiment,
		goal,
	} = action;

	const value =
		'id' === mode
			? `#${ action.attributes.value }`
			: action.attributes.value;

	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'click', ( ev ) => {
		const target = ev.target || ev.srcElement;
		if ( ! target || ! target.closest ) {
			return;
		} //end if

		if ( ! target.closest( value ) ) {
			return;
		} //end if

		convert( experiment, goal );
	} );

	[ ...document.querySelectorAll( value ) ].forEach( ( el ) =>
		el.addEventListener( 'click', () => convert( experiment, goal ) )
	);
} //end listenToClickEvent()
