/**
 * Internal dependencies
 */
import { getCookie, setCookie } from '../utils/cookies';

export function isStagingSite() {
	return !! getCookie( 'nabIsStagingSite' );
} //end isStagingSite()

export function maybeMarkSiteAsStaging( settings ) {
	const search = document.location.search;
	if ( settings.isStagingSite || /\bnabstaging\b/.test( search ) ) {
		setCookie( 'nabIsStagingSite', true );
	} //end if
} //end maybeMarkSiteAsStaging()

export function updateExperimentsWithPageViews( events, settings ) {
	removeOldExperimentsFromCookie( settings.experiments );

	events = events.filter( ( event ) => 'visit' === event.kind );
	if ( ! events.length ) {
		return;
	} //end if

	const now = new Date().getTime();
	setExperimentsWithPageViewsCookie( {
		...getExperimentsWithPageViews(),
		...events.reduce( ( result, event ) => {
			result[ event.experiment ] = now;
			return result;
		}, {} ),
	} );
} //end updateExperimentsWithPageViews()

let synchedGoals = [];
export function shouldConversionBeSynced( experimentId, goalIndex ) {
	const experimentsWithPageViews = getExperimentsWithPageViews();
	if ( ! experimentsWithPageViews[ experimentId ] ) {
		return false;
	} //end if

	if ( synchedGoals.includes( `${ experimentId }-${ goalIndex }` ) ) {
		return false;
	} //end if

	synchedGoals = [ ...synchedGoals, `${ experimentId }-${ goalIndex }` ];
	return true;
} //end shouldConversionBeSynced()

export function getExperimentsWithPageViews() {
	try {
		return JSON.parse( getCookie( 'nabExperimentsWithPageViews' ) );
	} catch ( e ) {
		return {};
	} //end try
} //end getExperimentsWithPageViews()

function setExperimentsWithPageViewsCookie( value ) {
	setCookie( 'nabExperimentsWithPageViews', JSON.stringify( value ), {
		expires: 120,
	} );
} //end setExperimentsWithPageViewsCookie()

let wasCookieCleaned = false;
function removeOldExperimentsFromCookie( experiments ) {
	if ( wasCookieCleaned ) {
		return;
	} //end if
	wasCookieCleaned = true;

	const experimentsWithPageViews = getExperimentsWithPageViews();
	const experimentIds = Object.keys( experiments );
	const experimentIdsWithPageViews = Object.keys( experimentsWithPageViews );

	const invalidExperimentIds = experimentIdsWithPageViews.filter(
		( id ) => ! experimentIds.includes( id )
	);

	const now = new Date().getTime();
	const TEN_DAYS_IN_MINUTES = 14400;
	const newExperimentsWithPageViews = experimentIdsWithPageViews.reduce(
		( result, id ) => {
			const expirationDate = experimentsWithPageViews[ id ];

			if ( invalidExperimentIds.includes( id ) ) {
				const minutesToLastValidation =
					Math.abs( now - expirationDate ) / 60000;
				if ( TEN_DAYS_IN_MINUTES < minutesToLastValidation ) {
					return result;
				} //end if

				return { ...result, [ id ]: expirationDate };
			} //end if

			return { ...result, [ id ]: now };
		},
		{}
	);

	setExperimentsWithPageViewsCookie( newExperimentsWithPageViews );
} //end removeOldExperimentsFromCookie()
