export const type = 'nab/window-width';

export function validate( attributes ) {
	const { condition, value, interval = {} } = attributes;

	const currentWidth =
		window.innerWidth ||
		document.documentElement.clientWidth ||
		document.body.clientWidth;

	switch ( condition ) {
		case 'is-greater-than':
			return currentWidth > value;

		case 'is-less-than':
			return currentWidth < value;

		case 'between':
			return (
				'number' === typeof interval.min &&
				'number' === typeof interval.max &&
				interval.min <= currentWidth &&
				currentWidth <= interval.max
			);
	} //end switch

	return false;
}
