export function setCookie( name, value, options ) {
	options = {
		path: '/',
		expires: 0,
		...options,
	};

	let cookie = `${ name }=${ encodeURIComponent( value ) };`;

	if ( 0 < options.expires ) {
		const expiration = new Date( new Date() * 1 + options.expires * 864e5 );
		cookie += ` expires=${ expiration.toUTCString() };`;
	} //end if

	cookie += ` path=${ options.path };`;

	document.cookie = cookie;
} //end setCookie()

export function getCookie( name ) {
	const cookies = document.cookie
		.split( ';' )
		.map( ( cookie ) => cookie.trim() )
		.reduce( ( memo, cookie ) => {
			memo[ cookie.split( '=' )[ 0 ] ] = cookie.split( '=' )[ 1 ];
			return memo;
		}, {} );

	if ( cookies[ name ] ) {
		return decodeURIComponent( cookies[ name ] );
	} //end if
} //end getCookie()

export function removeCookie( name ) {
	document.cookie = `${ name }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
} //end removeCookie()
