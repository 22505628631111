/**
 * WordPress dependencies.
 */
import domReady from '@wordpress/dom-ready';

/**
 * Internal dependencies
 */
import { addQueryArgs } from '../../utils/url';

const YOUTUBE_PLAY = 1;
const YOUTUBE_END = 0;

export default function listenToVideoPlaybackEvent( action, convert ) {
	if ( 'youtube' !== action?.attributes?.platform ) {
		return;
	} //end if

	domReady( () => {
		requestYouTubeApiScript( convert );
		listenToYouTubeVideoPlaybackEvent( action, convert );
	} );
} //end listenToClickEvent()

// =======
// HELPERS
// =======

let isYouTubeApiReady = false;
function requestYouTubeApiScript( convert ) {
	if ( document.querySelector( 'script[src*="youtube.com/iframe_api"]' ) ) {
		return;
	} //end if

	const tag = document.createElement( 'script' );
	tag.src = 'https://www.youtube.com/iframe_api';
	document.head.appendChild( tag );

	const fn = window.onYouTubeIframeAPIReady ?? ( () => {} );
	window.onYouTubeIframeAPIReady = () => {
		fn();
		isYouTubeApiReady = true;
		Object.keys( trackedYouTubeVideos ).forEach( ( videoId ) =>
			addYouTubeListeners( videoId, convert )
		);
	};
} //end requestYouTubeApiScript()

let trackedYouTubeVideos = {};
function listenToYouTubeVideoPlaybackEvent( action, convert ) {
	const {
		attributes: { event, videoId },
		experiment,
		goal,
	} = action;

	const video = trackedYouTubeVideos[ videoId ] ?? {};
	trackedYouTubeVideos = {
		...trackedYouTubeVideos,
		[ videoId ]: {
			...video,
			[ event ]: [ ...( video[ event ] ?? [] ), { experiment, goal } ],
		},
	};

	if ( isYouTubeApiReady ) {
		addYouTubeListeners( videoId, convert );
	} //end if
} //end listenToYouTubeVideoPlaybackEvent()

const youTubeVideosWithListeners = [];
function addYouTubeListeners( videoId, convert ) {
	if ( ! isYouTubeApiReady ) {
		return;
	} //end if

	const video = trackedYouTubeVideos[ videoId ];
	if ( ! video ) {
		return;
	} //end if

	if ( youTubeVideosWithListeners.includes( videoId ) ) {
		return;
	} //end if
	youTubeVideosWithListeners.push( videoId );

	const iframes = [
		...document.querySelectorAll( `iframe[src*="${ videoId }"]` ),
	].filter(
		( iframe ) =>
			iframe.src.includes( 'youtube' ) ||
			iframe.src.includes( 'youtu.be' )
	);

	iframes
		.filter( ( iframe ) => ! iframe.src.includes( 'enablejsapi' ) )
		.forEach( ( iframe ) => {
			iframe.src = addQueryArgs( iframe.src, { enablejsapi: 1 } );
		} );

	iframes.forEach( ( iframe ) => {
		new window.YT.Player( iframe, {
			events: {
				onStateChange: ( ev ) => {
					switch ( ev.data ) {
						case YOUTUBE_PLAY:
							return video.play && convert( video.play );

						case YOUTUBE_END:
							return video.end && convert( video.end );
					} //end switch
				},
			},
		} );
	} );
} //end addYouTubeListeners()
