/**
 * WordPress dependencies
 */
import domReady from '@wordpress/dom-ready';

/**
 * Internal dependencies
 */
import { getClosest } from '../../utils/dom';
import { getCookie } from '../../utils/cookies';
import { debounce } from '../../utils/helpers';

let actions = [];

domReady( () => {
	const $ = window.jQuery;
	if ( ! $ ) {
		return;
	} //end if

	$.ajaxPrefilter( ( options, originalOptions ) => {
		try {
			if ( 'nf_ajax_submit' !== originalOptions.data.action ) {
				return;
			} //end if
		} catch ( e ) {
			return;
		} //end try

		options.data +=
			'&nab_experiments_with_page_view=' +
			encodeURIComponent( generateExperimentsWithPageViewValue() );
	} );
} );

export default function prepareFormForSyncingEventInWordPress( action ) {
	const { experiment, alternative } = action;

	actions = [ ...actions, { experiment, alternative } ];

	addEventListenersToExtendFormFields();
} //end prepareFormForSyncingEventInWordPress()

let areListenersReady = false;
function addEventListenersToExtendFormFields() {
	if ( areListenersReady ) {
		return;
	} //end if
	areListenersReady = true;

	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'click', maybeAddHiddenFieldInForm );
	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'click', maybeAddHiddenFieldInForm, true );
	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'keyup', maybeAddHiddenFieldInForm );
	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'keyup', maybeAddHiddenFieldInForm, true );
} //end addEventListenersToExtendFormFields()

const maybeAddHiddenFieldInForm = debounce(
	( ev ) => {
		const target = ev.target || ev.srcElement;
		const form = getClosest( target, 'form' );

		if ( ! form ) {
			return;
		} //end if

		const value = generateExperimentsWithPageViewValue();
		if ( ! value ) {
			return;
		} //end if

		const hiddenField = getHiddenField( form );
		hiddenField.setAttribute( 'value', value );
	},
	1000,
	{ leading: true }
);

function getHiddenField( form ) {
	let hiddenField = form.querySelector(
		'input[name=nab_experiments_with_page_view]'
	);
	if ( hiddenField ) {
		return hiddenField;
	} //end if

	hiddenField = document.createElement( 'input' );
	hiddenField.setAttribute( 'type', 'hidden' );
	hiddenField.setAttribute( 'name', 'nab_experiments_with_page_view' );

	form.appendChild( hiddenField );

	return hiddenField;
} //end getHiddenField()

function generateExperimentsWithPageViewValue() {
	const experiments = getExperimentsWithPageView();
	const relevantActions = actions.filter( ( { experiment } ) =>
		experiments.includes( experiment )
	);
	return relevantActions
		.map(
			( { experiment, alternative } ) =>
				`${ experiment }:${ alternative }`
		)
		.join( ',' );
} //end generateExperimentsWithPageViewValue()

function getExperimentsWithPageView() {
	try {
		const value = JSON.parse( getCookie( 'nabExperimentsWithPageViews' ) );
		return Object.keys( value ).map( ( id ) => parseInt( id ) );
	} catch {
		return {};
	} //end try
} //end getExperimentsWithPageView()
