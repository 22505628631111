/**
 * WordPress dependencies
 */
import domReady from '@wordpress/dom-ready';

/**
 * Internal dependencies
 */
import { getCookie } from '../utils/cookies';
import { xPath } from './xpath';
import { track } from './sync';

const ROW_HEIGHT_FOR_SHOW_TIME_TRACKING = 50;
const SHOW_TIME_UPDATE_INTERVAL_IN_MS = 500;
const SHOW_TIME_UPDATE_MAX_DURATION_IN_MS = 2 * 60 * 1000; // 2 minutes

export function initHeatmapTracking( settings ) {
	domReady( () => doInitHeatmapTracking( settings ) );
} //end initHeatmapTracking()

function doInitHeatmapTracking( settings ) {
	if ( ! settings.heatmapTracking.length ) {
		return;
	} //end if

	const startTime = new Date().getTime();
	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'click', ( ev ) => {
		const event = createClickEventWithoutExperimentInfo(
			ev,
			startTime,
			settings
		);
		if ( ! event ) {
			return;
		} //end if

		const events = settings.heatmapTracking.map( ( { id, type } ) => ( {
			...getExperimentInfo( id, type, settings ),
			...event,
		} ) );

		track( events, settings );
	} );

	let maxScroll = window.pageYOffset + window.innerHeight;
	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'scroll', () => {
		const bottom = window.pageYOffset + window.innerHeight;
		if ( bottom > maxScroll ) {
			maxScroll = bottom;
		} //end if
	} );

	const showTimes = new Array(
		Math.ceil(
			document.body.clientHeight / ROW_HEIGHT_FOR_SHOW_TIME_TRACKING
		)
	).fill( 0 );
	let numOfShowTimeUpdates = 0;
	const maxNumOfShowTimeUpdates = Math.ceil(
		SHOW_TIME_UPDATE_MAX_DURATION_IN_MS / SHOW_TIME_UPDATE_INTERVAL_IN_MS
	);
	function updateShowTimes() {
		const top = window.pageYOffset;
		const bottom = top + window.innerHeight;

		const firstVisibleRow = Math.ceil(
			top / ROW_HEIGHT_FOR_SHOW_TIME_TRACKING
		);
		const lastVisibleRow = Math.min(
			Math.floor( bottom / ROW_HEIGHT_FOR_SHOW_TIME_TRACKING ),
			showTimes.length
		);

		for ( let row = firstVisibleRow; row <= lastVisibleRow; ++row ) {
			++showTimes[ row ];
		} //end for

		++numOfShowTimeUpdates;
		if ( numOfShowTimeUpdates < maxNumOfShowTimeUpdates ) {
			setTimeout( updateShowTimes, SHOW_TIME_UPDATE_INTERVAL_IN_MS );
		} //end if
	} //end updateShowTimes()
	setTimeout( updateShowTimes, SHOW_TIME_UPDATE_INTERVAL_IN_MS );

	const firstFold = window.innerHeight;
	const documentHeight = document.body.clientHeight;
	// eslint-disable-next-line @wordpress/no-global-event-listener
	window.addEventListener( 'beforeunload', () => {
		const event = {
			...createScrollEventWithoutExperimentInfo(),
			firstFold,
			sweetSpot: findSweetSpot( showTimes ),
			maxScroll,
			documentHeight,
		};
		const events = settings.heatmapTracking.map( ( { id, type } ) => ( {
			...getExperimentInfo( id, type, settings ),
			...event,
		} ) );

		if ( ! events.length ) {
			return;
		} //end if
		track( events, settings );
	} );
} //end doInitHeatmapTracking()

function createClickEventWithoutExperimentInfo( ev, start, settings ) {
	const target = ev.target || ev.srcElement;
	if ( ! target ) {
		return false;
	} //end if

	const rect = target.getBoundingClientRect();
	if ( ! rect || ! rect.width || ! rect.height ) {
		return false;
	} //end if

	const x = ( ev.clientX - rect.left ) / rect.width;
	const y = ( ev.clientY - rect.top ) / rect.height;

	return {
		kind: 'click',
		timeToClick: Math.round( ( new Date().getTime() - start ) / 1000 ),
		windowWidth: document.body.offsetWidth || 0,
		xpath: xPath( target, settings.optimizeXPath ),
		x,
		y,
	};
} //end createClickEventWithoutExperimentInfo()

function createScrollEventWithoutExperimentInfo() {
	return {
		kind: 'scroll',
		windowWidth: document.body.offsetWidth || 0,
	};
} //end createScrollEventWithoutExperimentInfo()

function getExperimentInfo( experimentId, type, settings ) {
	if ( 'heatmap' === type ) {
		return {
			alternative: 0,
			experiment: experimentId,
		};
	} //end if

	const alternativeCookieValue = getCookie( 'nabAlternative' );
	const experiment = settings.experiments[ experimentId ];
	return {
		alternative: alternativeCookieValue % experiment.alternatives.length,
		experiment: experimentId,
	};
} //end getExperimentInfo()

function findSweetSpot( showTimes ) {
	const collapsedShowTimes = collapseShowTimes( showTimes );

	const sweetSpotValue = collapsedShowTimes.reduce(
		( maxValue, { value } ) => Math.max( maxValue, value ),
		0
	);
	const sweetSpots = collapsedShowTimes.filter(
		( { value } ) => value === sweetSpotValue
	);

	const maxSweetSpotAreaSize = sweetSpots.reduce(
		( maxSize, { size } ) => Math.max( size, maxSize ),
		0
	);
	const largestSweetSpots = sweetSpots.filter(
		( { size } ) => size === maxSweetSpotAreaSize
	);

	const sweetSpot = pickRandom( largestSweetSpots );
	return (
		ROW_HEIGHT_FOR_SHOW_TIME_TRACKING *
		( sweetSpot.start + Math.floor( sweetSpot.size / 2 ) )
	);
} //end findSweetSpot()

function collapseShowTimes( showTimes ) {
	return showTimes.reduce( ( result, value, index ) => {
		const last = result[ result.length - 1 ];

		if ( last && last.value === value ) {
			++last.size;
		} else {
			result.push( { start: index, value, size: 1 } );
		} //end if

		return result;
	}, [] );
} //end collapseShowTimes()

function pickRandom( list ) {
	const index = Math.round( Math.random() * ( list.length - 1 ) );
	return list[ index ];
} //end pickRandom()
