/**
 * Internal dependencies
 */
import { getClosest } from '../../utils/dom';

export default function listenToClickExternalLinkEvent( action, convert ) {
	const maybeConvert = ( anchorTag ) => {
		const {
			attributes: { mode, value },
			experiment,
			goal,
		} = action;
		const href = anchorTag.getAttribute( 'href' ) || '';

		switch ( mode ) {
			case 'exact':
				return href === value && convert( experiment, goal );

			case 'partial':
				return (
					0 <= href.indexOf( value ) && convert( experiment, goal )
				);

			case 'start':
				return href.startsWith( value ) && convert( experiment, goal );

			case 'end':
				return href.endsWith( value ) && convert( experiment, goal );

			case 'regex':
				try {
					return (
						new RegExp( value ).test( href ) &&
						convert( experiment, goal )
					);
				} catch ( e ) {}
		} //end switch
	};

	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener( 'click', ( ev ) => {
		const target = ev.target || ev.srcElement;
		const anchorTag = getClosest( target, 'a' );
		if ( ! anchorTag ) {
			return;
		} //end if

		maybeConvert( anchorTag );
	} );

	[ ...document.querySelectorAll( 'a' ) ].forEach( ( el ) =>
		el.addEventListener( 'click', () => maybeConvert( el ) )
	);
} //end listenToClickExternalLinkEvent()
