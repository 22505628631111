export function addSingleAction( name, callback ) {
	if ( ! window.nabAddSingleAction ) {
		console.error( // eslint-disable-line
			'Nelio A/B Testing - Helper function missing: nabAddSingleAction'
		);
		return;
	} //end if
	window.nabAddSingleAction( name, callback );
} //end addSingleAction()

export function doSingleAction( name ) {
	if ( ! window.nabDoSingleAction ) {
		console.error( // eslint-disable-line
			'Nelio A/B Testing - Helper function missing: nabDoSingleAction'
		);
		return;
	} //end if
	setTimeout( () => window.nabDoSingleAction( name ), 1 );
} //end doSingleAction()
