/**
 * Internal dependencies
 */
import { getCookie } from '../../utils/cookies';

export const type = 'nab/user-login';

export function validate( { condition } ) {
	const isUserLoggedIn = !! getCookie( 'nabIsUserLoggedIn' );

	if ( condition === 'is-logged-in' && isUserLoggedIn ) {
		return true;
	} //end if

	if ( condition === 'is-not-logged-in' && isUserLoggedIn === false ) {
		return true;
	} //end if

	return false;
}
