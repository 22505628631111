const Node = window.Node;
const XPathResult = window.XPathResult;

// Code from Chromium project.
// https://github.com/chromium/chromium/blob/master/third_party/blink/renderer/devtools/front_end/elements/DOMPath.js

export function getElementByXPath( xpath ) {
	return document.evaluate(
		xpath,
		document,
		null,
		XPathResult.FIRST_ORDERED_NODE_TYPE,
		null
	).singleNodeValue;
} //end getElementByXPath()

export function xPath( node, optimized ) {
	if ( node.nodeType === Node.DOCUMENT_NODE ) {
		return '/';
	} //end if

	const steps = [];

	let contextNode = node;
	while ( contextNode ) {
		const step = _xPathValue( contextNode, optimized );
		if ( ! step ) {
			break; // Error - bail out early.
		} //end if

		steps.push( step );
		if ( step.optimized ) {
			break;
		} //end if

		contextNode = contextNode.parentNode;
	} //end while

	steps.reverse();
	return (
		( steps.length && steps[ 0 ].optimized ? '' : '/' ) + steps.join( '/' )
	);
} //end xPath()

function _xPathValue( node, optimized ) {
	let ownValue;

	const ownIndex = _xPathIndex( node );
	if ( ownIndex === -1 ) {
		return null; // Error.
	} //end if

	switch ( node.nodeType ) {
		case Node.ELEMENT_NODE:
			if ( optimized && node.getAttribute( 'id' ) ) {
				return new Step(
					'//*[@id="' + node.getAttribute( 'id' ) + '"]',
					true
				);
			} //end if
			ownValue = node.localName;
			break;

		case Node.ATTRIBUTE_NODE:
			ownValue = '@' + node.nodeName;
			break;

		case Node.TEXT_NODE:
		case Node.CDATA_SECTION_NODE:
			ownValue = 'text()';
			break;

		case Node.PROCESSING_INSTRUCTION_NODE:
			ownValue = 'processing-instruction()';
			break;

		case Node.COMMENT_NODE:
			ownValue = 'comment()';
			break;

		case Node.DOCUMENT_NODE:
			ownValue = '';
			break;

		default:
			ownValue = '';
			break;
	} //end switch

	if ( ownIndex > 0 ) {
		ownValue += '[' + ownIndex + ']';
	} //end if

	return new Step( ownValue, node.nodeType === Node.DOCUMENT_NODE );
}

function _xPathIndex( node ) {
	// Returns -1 in case of error,
	//          0 if no siblings matching the same expression,
	//          <XPath index among the same expression-matching sibling nodes> otherwise.
	function areNodesSimilar( left, right ) {
		if ( left === right ) {
			return true;
		} //end if

		if (
			left.nodeType === Node.ELEMENT_NODE &&
			right.nodeType === Node.ELEMENT_NODE
		) {
			return left.localName === right.localName;
		} //end if

		if ( left.nodeType === right.nodeType ) {
			return true;
		} //end if

		// XPath treats CDATA as text nodes.
		const leftType =
			left.nodeType === Node.CDATA_SECTION_NODE
				? Node.TEXT_NODE
				: left.nodeType;
		const rightType =
			right.nodeType === Node.CDATA_SECTION_NODE
				? Node.TEXT_NODE
				: right.nodeType;
		return leftType === rightType;
	} //end areNodesSimilar()

	const siblings = node.parentNode ? node.parentNode.children : null;
	if ( ! siblings ) {
		return 0; // Root node - no siblings.
	} //end if

	let hasSameNamedElements;
	for ( let i = 0; i < siblings.length; ++i ) {
		if (
			areNodesSimilar( node, siblings[ i ] ) &&
			siblings[ i ] !== node
		) {
			hasSameNamedElements = true;
			break;
		} //end if
	} //end for

	if ( ! hasSameNamedElements ) {
		return 0;
	} //end if

	let ownIndex = 1; // XPath indices start with 1.
	for ( let i = 0; i < siblings.length; ++i ) {
		if ( areNodesSimilar( node, siblings[ i ] ) ) {
			if ( siblings[ i ] === node ) {
				return ownIndex;
			} //end if
			++ownIndex;
		} //end if
	} //end for
	return -1; // An error occurred: |node| not found in parent's children.
} //end _xPathIndex()

class Step {
	constructor( value, optimized ) {
		this.value = value;
		this.optimized = optimized || false;
	}

	toString() {
		return this.value;
	}
} //end class
