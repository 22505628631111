/**
 * Internal dependencies
 */
import uuid from 'uuid/v4';

/**
 * Internal dependencies
 */
import { getCookie } from '../utils/cookies';
import { addQueryArgs } from '../utils/url';
import {
	isStagingSite,
	shouldConversionBeSynced,
	updateExperimentsWithPageViews,
} from '../utils/tracking';
import { getActiveSegments } from '../utils/segments';

export function sendViews( experimentIds = [], settings ) {
	if ( 'object' !== typeof experimentIds ) {
		experimentIds = [ experimentIds ];
	} //end if

	const events = experimentIds
		.map( ( id ) => generatePageViewEvent( id, settings ) )
		.filter( ( event ) => !! event );

	if ( ! events.length ) {
		return;
	} //end if

	track( events, settings );
} //end trackPageViews()

export function sendConversions( events, settings ) {
	if ( ! events || 0 === events.length ) {
		return;
	} //end if

	if ( 'undefined' === typeof events[ 0 ] ) {
		events = [ events ];
	} //end if

	const { experiments } = settings;
	events = events
		.map( ( { experiment, goal } ) =>
			generateConversionEvent( experiment, goal, experiments )
		)
		.filter( ( event ) => !! event );

	track( events, settings );
} //end sendConversions()

export function track( events, settings ) {
	updateExperimentsWithPageViews( events, settings );

	const { siteId, trackingUrl, timezone } = settings;

	events = events.filter( ( event ) =>
		'conversion' === event.kind
			? shouldConversionBeSynced( event.experiment, event.goal )
			: true
	);

	if ( ! events.length ) {
		return;
	} //end if

	if ( isStagingSite() ) {
		events.forEach( ( event ) => console.info( '[Staging] Event', event ) ); // eslint-disable-line
		return;
	} //end if

	const timestamp = new Date().toISOString();
	events = events.map( ( event ) => ( {
		...event,
		id: uuid(),
		timezone,
		timestamp,
	} ) );

	let src = trackingUrl;
	src = addQueryArgs( src, { e: window.btoa( JSON.stringify( events ) ) } );
	src = addQueryArgs( src, { a: siteId } );
	const image = document.createElement( 'img' );
	image.setAttribute( 'src', src );
} //end track()

function generatePageViewEvent( experimentId, settings ) {
	const { experiments, heatmapTracking } = settings;

	const experiment = experiments[ experimentId ];

	if ( ! experiment ) {
		return maybeGeneratePageViewEventForHeatmap(
			experimentId,
			heatmapTracking
		);
	} //end if

	const alternativeCookieValue = getCookie( 'nabAlternative' );
	if ( undefined === alternativeCookieValue ) {
		return;
	} //end if

	const segments = getActiveSegments( experimentId );
	if ( ! segments.length ) {
		return;
	} //end if

	const alternative = alternativeCookieValue % experiment.alternatives.length;

	return {
		kind: 'visit',
		experiment: experimentId,
		alternative,
		segments,
	};
} //end generatePageViewEvent()

function maybeGeneratePageViewEventForHeatmap( experimentId, heatmapTracking ) {
	const ids = heatmapTracking.map( ( { id } ) => id );
	if ( ! ids.includes( experimentId ) ) {
		return;
	} //end if

	return {
		kind: 'visit',
		experiment: experimentId,
		alternative: 0,
	};
} //end maybeGeneratePageViewEventForHeatmap()

function generateConversionEvent( experimentId, goalIndex, experiments ) {
	const experiment = experiments[ experimentId ];
	if ( ! experiment ) {
		return;
	} //end if

	if ( experiment.goals.length <= goalIndex ) {
		return;
	} //end if

	const alternativeCookieValue = getCookie( 'nabAlternative' );
	if ( undefined === alternativeCookieValue ) {
		return;
	} //end if

	const segments = getActiveSegments( experimentId );
	if ( ! segments.length ) {
		return;
	} //end if

	const alternative = alternativeCookieValue % experiment.alternatives.length;
	return {
		kind: 'conversion',
		experiment: experimentId,
		alternative,
		goal: goalIndex,
		segments,
	};
} //end generateConversionEvent()
