/**
 * WordPress dependencies
 */
import domReady from '@wordpress/dom-ready';
import { createHooks } from '@wordpress/hooks';

/**
 * Internal dependencies
 */
import { sendConversions } from './sync';
import { getAllActions, getConversionEvents } from './utils';

export const hooks = createHooks();

export function initConversionTracking( settings ) {
	const { currentPostId } = settings;

	domReady( () => addEventListeners( settings ) );
	if ( currentPostId ) {
		sendConvertingPageViews( settings );
	} //end if
} //end initConversionTracking()

function sendConvertingPageViews( settings ) {
	const { currentPostId, experiments } = settings;

	const events = getConversionEvents(
		experiments,
		( action ) =>
			'nab/page-view' === action.type &&
			action.attributes.postId === currentPostId
	);
	sendConversions( events, settings );
} //end sendConvertingPageViews()

function addEventListeners( settings ) {
	getAllActions( settings.experiments )
		.filter( ( action ) => 'nab/page-view' !== action.type )
		.forEach( ( action ) => addEventListenersRequiredByAction( action ) );
} //end addEventListeners()

function addEventListenersRequiredByAction( action ) {
	hooks.doAction( 'nab.addEventListenersRequiredByAction', action );
} //end addEventListenersRequiredByAction()
