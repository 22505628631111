export function getClosest( node, nodeType ) {
	do {
		if ( nodeType.toLowerCase() === node.nodeName.toLowerCase() ) {
			return node;
		} //end if
		node = node.parentNode;
	} while ( node );

	return false;
} //end getClosest()
