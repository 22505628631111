/**
 * Internal dependencies
 */
import { getQueryArgs } from '../url';

export const type = 'nab/query-parameter';

export function validate( attributes ) {
	const { name, condition, value } = attributes;

	const queryArgs = getQueryArgs( document.URL );

	switch ( condition ) {
		case 'is-equal-to':
			return queryArgs[ name ] === value;

		case 'is-not-equal-to':
			return queryArgs[ name ] !== value;

		case 'contains':
			return queryArgs[ name ] && queryArgs[ name ].includes( value );

		case 'does-not-contain':
			return (
				! queryArgs[ name ] ||
				( queryArgs[ name ] && ! queryArgs[ name ].includes( value ) )
			);

		case 'exists':
			return 'undefined' !== typeof queryArgs[ name ];

		case 'does-not-exist':
			return 'undefined' === typeof queryArgs[ name ];
	} //end switch

	return false;
}
