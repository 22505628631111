/**
 * Internal dependencies
 */
import { getCookie } from '../cookies';

export const type = 'nab/browser';

export function validate( attributes ) {
	const { name, condition, value } = attributes;

	const cookieValue = getCookie( name );

	switch ( condition ) {
		case 'is-equal-to':
			return cookieValue === value;

		case 'is-not-equal-to':
			return cookieValue !== value;

		case 'contains':
			return cookieValue && cookieValue.includes( value );

		case 'does-not-contain':
			return (
				! cookieValue ||
				( cookieValue && ! cookieValue.includes( value ) )
			);

		case 'exists':
			return 'undefined' !== typeof cookieValue;

		case 'does-not-exist':
			return 'undefined' === typeof cookieValue;
	} //end switch

	return false;
}
