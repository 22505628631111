/**
 * External dependencies
 */
import 'es6-symbol';

/**
 * Internal dependencies
 */
import { hooks as _hooks, initConversionTracking } from './conversions';
import { initHeatmapTracking } from './heatmaps';
import { initDefaultListeners } from './listeners';
import { sendConversions, sendViews } from './sync';

import { cleanOldSegments } from '../utils/segments';
import { doesVisitorParticipate } from '../utils/participation';
import { addSingleAction, doSingleAction } from '../utils/actions';

let pendingEvents = [];
let settings = false;
export function init( initialSettings ) {
	if ( ! initialSettings ) {
		return;
	} //end if

	if ( settings ) {
		return;
	} //end if
	settings = initialSettings;

	if ( ! doesVisitorParticipate( settings ) ) {
		return;
	} //end if

	cleanOldSegments( settings.experiments );
	initDefaultListeners( convert );
	initConversionTracking( settings );
	initHeatmapTracking( settings );

	doSingleAction( 'main-ready' );
} //end init()

export function view( experimentIds ) {
	if ( ! settings ) {
		pendingEvents = [ ...pendingEvents, { type: 'view', experimentIds } ];
		return;
	} //end if

	if ( ! doesVisitorParticipate( settings ) ) {
		return;
	} //end if

	sendViews( experimentIds, settings );
} //end view()

export function trigger( eventName ) {
	if ( ! settings ) {
		pendingEvents = [ ...pendingEvents, { type: 'trigger', eventName } ];
		return;
	} //end if

	if ( ! doesVisitorParticipate( settings ) ) {
		return;
	} //end if

	const experimentIds = Object.keys( settings.experiments );
	const { experiments } = settings;
	const events = experimentIds.reduce( ( memo, id ) => {
		const experiment = experiments[ id ];
		experiment.goals.forEach( ( goal, index ) => {
			const hasCustomEventAction = goal.conversionActions.reduce(
				( found, { type, attributes = {} } ) =>
					found ||
					( 'nab/custom-event' === type &&
						eventName === attributes.eventName ),
				false
			);
			if ( hasCustomEventAction ) {
				memo.push( { experiment: Number.parseInt( id ), goal: index } );
			} //end if
		} );
		return memo;
	}, [] );

	sendConversions( events, settings );
} //end trigger()

/**
 * Sends a conversion for the given experiment/goal pair(s).
 *
 * @param experiment int|array experiment ID or list of {experiment,goal} objects.
 * @param goal       int       goal
 */
export function convert( experiment, goal ) {
	const events = ! experiment[ 0 ] ? [ { experiment, goal } ] : experiment;

	if ( ! settings ) {
		pendingEvents = [
			...pendingEvents,
			...events.map( ( ev ) => ( {
				type: 'convert',
				...ev,
			} ) ),
		];
		return;
	} //end if

	if ( ! doesVisitorParticipate( settings ) ) {
		return;
	} //end if

	sendConversions(
		events.map( ( ev ) => ( {
			experiment: ev.experiment,
			goal: ev.goal,
		} ) ),
		settings
	);
} //end convert()

export const hooks = _hooks;

export function debug() {
	return settings;
} //end debug()

addSingleAction( 'main-ready', () => {
	pendingEvents.forEach( ( event ) => {
		switch ( event.type ) {
			case 'view':
				return view( event.experimentIds );
			case 'trigger':
				return trigger( event.eventName );
			case 'convert':
				return convert( event.experiment, event.goal );
		} //end switch
	} );
	pendingEvents = [];
} );

addSingleAction( 'valid-content', () => {
	doSingleAction( 'main-init-available' );
} );
