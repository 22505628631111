export function debounce( f, wait, opts = {} ) {
	let timeout = 0;
	return ( ...args ) => {
		const g = () => {
			f( ...args );
			timeout = 0;
		};

		if ( timeout ) {
			clearTimeout( timeout );
			timeout = setTimeout( g, wait );
			return;
		} //end if

		if ( opts.leading ) {
			g();
			timeout = 1;
			return;
		} //end if

		timeout = setTimeout( g, wait );
	};
} //end debounce()
