/**
 * External dependencies
 */
import UaParser from 'ua-parser-js';

export const type = 'nab/browser';

export function validate( attributes ) {
	const { condition, values } = attributes;

	const visitorBrowser = getVisitorBrowser();

	switch ( condition ) {
		case 'is-equal-to':
			return values.some( ( { value } ) =>
				isSameBrowser( value, visitorBrowser )
			);

		case 'is-not-equal-to':
			return values.every(
				( { value } ) => ! isSameBrowser( value, visitorBrowser )
			);
	} //end switch

	return false;
}

function getVisitorBrowser() {
	const parser = new UaParser();
	const browserData = parser.getBrowser();
	return browserData.name;
} //end getVisitorBrowser()

function isSameBrowser( browserName, visitorBrowserName ) {
	return browserName === visitorBrowserName;
} //end isSameBrowser()
