/**
 * Internal dependencies
 */
import { getCookie, setCookie } from '../utils/cookies';
import { isValidSegmentationRule } from './segmentation-rules';
import { getExperimentsWithPageViews } from '../utils/tracking';

export async function updateSegmentationSettings(
	settings,
	whenToRecalculateGeoData
) {
	const { experiments, whenIsGeoDataRequired, site } = settings;

	if (
		! isSegmentationGeoDataOutdated() ||
		whenToRecalculateGeoData !== whenIsGeoDataRequired
	) {
		updateActiveSegments( experiments );
		return;
	} //end if

	if ( 'never' === whenIsGeoDataRequired ) {
		return;
	} //end if

	try {
		const segmentationSettings = getSegmentationSettings();
		const res = await window.fetch(
			`https://api.nelioabtesting.com/v1/ipc?siteId=${ site }`
		);
		const data = await res.text();
		const jsonData = JSON.parse( data );
		segmentationSettings.geo = {
			ipAddress: jsonData.ip,
			location: jsonData.location,
			lastUpdate: new Date().toISOString(),
		};
		setSegmentationSettings( segmentationSettings );
	} catch ( e ) {}

	updateActiveSegments( experiments );
} //end updateSegmentationSettings()

export function isVisitorInASegmentOfEveryTest( experiments ) {
	const activeSegments = getAllActiveSegments();
	return Object.keys( experiments ).every(
		( experimentId ) => ! isEmptyArray( activeSegments[ experimentId ] )
	);
} //end isVisitorInASegmentOfEveryTest()

export function getSegmentationGeoData() {
	const segmentationSettings = getSegmentationSettings();
	return segmentationSettings.geo || {};
} //end getSegmentationGeoData()

export function getActiveSegments( experimentId ) {
	const activeSegments = getAllActiveSegments();
	return activeSegments[ experimentId ] || [];
} //end getActiveSegments()

export function cleanOldSegments( experiments ) {
	const segmentationSettings = getSegmentationSettings();
	const activeSegments = segmentationSettings.activeSegments || {};

	const currentExperimentIds = Object.keys( experiments );
	const viewedExperimentIds = Object.keys( getExperimentsWithPageViews() );

	// Remove experiments that does not exist or didn't have views.
	segmentationSettings.activeSegments = Object.keys( activeSegments ).reduce(
		( result, experimentId ) => {
			if (
				currentExperimentIds.includes( experimentId ) ||
				viewedExperimentIds.includes( experimentId )
			) {
				result[ experimentId ] = activeSegments[ experimentId ];
			} //end if
			return result;
		},
		{}
	);

	setSegmentationSettings( segmentationSettings );
} //end cleanOldSegments()

export function getSegmentationSettings() {
	try {
		return JSON.parse( getCookie( 'nabSegmentation' ) || '{}' );
	} catch ( e ) {
		return {};
	} //end try
} //end getSegmentationSettings()

function getAllActiveSegments() {
	const segmentationSettings = getSegmentationSettings();
	const activeSegments = segmentationSettings.activeSegments || {};
	return activeSegments;
} //end getAllActiveSegments()

function setSegmentationSettings( settings ) {
	setCookie( 'nabSegmentation', JSON.stringify( settings ), {
		expires: 120,
	} );
} //end setSegmentationSettings()

function isSegmentationGeoDataOutdated() {
	const geoData = getSegmentationGeoData();
	if ( ! geoData.lastUpdate ) {
		return true;
	} //end if

	const lastUpdate = geoData.lastUpdate;
	const now = new Date();
	const lastUpdateDate = new Date( lastUpdate );
	const diffTime = Math.abs( now - lastUpdateDate );
	const diffDays = Math.ceil( diffTime / ( 1000 * 60 * 60 * 24 ) );

	return isNaN( diffDays ) || diffDays > 7; // Update segmentation data every week.
} //end isSegmentationGeoDataOutdated()

function updateActiveSegments( experiments ) {
	const segmentationSettings = getSegmentationSettings();
	const previousActiveSegments = segmentationSettings.activeSegments || {};

	const activeSegments = {};
	for ( const experimentId in experiments ) {
		if ( experiments.hasOwnProperty( experimentId ) ) {
			activeSegments[ experimentId ] = calculateActiveSegments(
				experiments[ experimentId ].segments
			);
		} //end if
	} //end for

	segmentationSettings.activeSegments = {
		...activeSegments,
		...previousActiveSegments,
	};
	setSegmentationSettings( segmentationSettings );
} //end updateActiveSegments()

function isEmptyArray( arr ) {
	return ! arr || ! arr.length;
} //end isEmptyArray()

function calculateActiveSegments( segments ) {
	// When no segments, return the global segment.
	if ( isEmptyArray( segments ) ) {
		return [ 0 ];
	} //end if

	const activeSegments = segments
		.map( ( { segmentationRules }, index ) =>
			segmentationRules.every( isValidSegmentationRule )
				? index + 1
				: false
		)
		.filter( ( segmentIndex ) => segmentIndex );

	return activeSegments.length ? [ 0, ...activeSegments ] : [];
} //end calculateActiveSegments()
